import { prefetchCurrencies, currencies, DEFAULT_CURRENCY } from '@js/core/currencies.js';

const popularCurrencies = ['GBP', 'EUR', 'USD'];

export default {
    data() {
        return {
            loadingCurrencies: true,
            currencies,
        };
    },
    computed: {
        orderedCurrencies() {
            return _.keys(this.currencies).sort((currencyA, currencyB) => {
                let compareA = popularCurrencies.indexOf(currencyA) * -1;
                let compareB = popularCurrencies.indexOf(currencyB) * -1;
                if (compareA === compareB) {
                    compareA = currencyA;
                    compareB = currencyB;
                }
                return compareA < compareB ? -1 : 1;
            });
        },
    },
    methods: {
        symbol(currency) {
            return this.currencies[currency]?.symbol || currency;
        },
        convert(amount, to, from) {
            if (this.loadingCurrencies || !amount) {
                return 0;
            }

            if (_.isObject(amount)) {
                from = from || amount.currency;
                amount = amount.amount;
            } else {
                from = from || DEFAULT_CURRENCY;
            }

            const toRate = this.currencies[to]?.rate || 1;
            const fromRate = this.currencies[from]?.rate || 1;

            return _.round((amount / fromRate) * toRate, 2);
        },
    },
    created() {
        // This will only send a request once per browser session.
        prefetchCurrencies().then(() => {
            this.loadingCurrencies = false;
            this.currencies = currencies;
        });
    },
};
